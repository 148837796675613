h2 {
    text-transform: uppercase;
}

p {
    text-align: justify;
}

.image-100-width {
    width: 100%;
}