@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.assignment {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.alert-fade-fix {
    opacity: 1!important;
}

.nalogaHeadingItem {
    margin-left: 1rem;
}

.uppercase {
    text-transform: uppercase;
}

.border-none {
    border: none!important;
}

.text-2-rem h2 {
    font-size: 3rem;
}

.center-center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-vertical-center {
    display: flex;
    align-items: center;
}

.table-row {
    display: flex;
}

.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.padding-1 {
    padding: 1rem;
}

.margin-top-1 {
    margin-top: 1rem;
}

.padding-05rem {
    padding: .5rem;
}

.square10 {
    width: 10%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square20 {
    width: 20%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square25 {
    width: 25%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square40 {
    width: 40%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square50 {
    width: 50%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square60 {
    width: 60%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square75 {
    width: 75%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square80 {
    width: 80%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square90 {
    width: 90%;
    border: 1.5px solid #000;
    padding: 10px;
}

.flex-20 {
    width: 20%;
}

.flex-80 {
    width: 80%;
}

.flex-25 {
    width: 25%;
}

.flex-75 {
    width: 75%;
}

.flex-30 {
    width: 30%;
}

.flex-70 {
    width: 70%;
}

.width20 {
    width: 20%;
    padding: 10px;
}

.width80 {
    width: 80%;
    padding: 10px;
}

.width50 {
    width: 50%;
    padding: 10px;
}

.task-input {
    font-family: 'Caveat', cursive;
}

.task-input textarea {
    font-size: 1.5rem;
}

.task-input-size-normal textarea {
    font-size: 1rem;
}

.large-number-input {
    font-size: 2rem!important;
}

.clickable {
    cursor: pointer;
}

.x {
    font-family: 'Caveat', cursive;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.obkrozi-vrednote {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
}

.obkrozi-vrednote div {
    flex-grow: 1;
    width: 33%;
    padding-bottom: .2rem;
    margin-bottom: 1rem;
}

.circled {
    position: relative;
}
.circled:after {
    border: .25em solid #16264c;
    border-radius: 1.5em;
    bottom: -.3em;
    content: '';
    left: -1em;
    position: absolute;
    right: 10em;
    top: -.1em;
}

.circled-number {
    position: relative;
}

.circled-number:after {
    border: .15em solid #16264c;
    border-radius: 1.5em;
    bottom: -.3em;
    content: '';
    left: -.5em;
    position: absolute;
    right: -0.25em;
    top: -.1em;
}

.notebook-like {
	color: #000000;
	border: 1px solid #EEEEEE;
	box-shadow: 1px 1px 0 #DDDDDD;
	display: inline-block;
	vertical-align: top;
    font-family: 'Caveat', cursive;
	line-height: 20px;
	margin: 2% auto;
	padding: 22px 20px 3px 40px;
	min-height: 200px;
	width: 600px;
	background-color: #FFFFFF;
	background-image: -moz-linear-gradient(top, transparent, transparent 19px, #E7EFF8 0px), -moz-radial-gradient(4% 27%, circle closest-corner, #f5f5f5, #f5f5f5 30%, transparent 0%), -moz-radial-gradient(3.9% 29%, circle closest-corner, #CCCCCC, #CCCCCC 34.5%, transparent 0%);
	background-image: -webkit-linear-gradient(top, transparent, transparent 19px, #E7EFF8 0), -webkit-radial-gradient(4% 27%, circle closest-corner, #f5f5f5, #f5f5f5 30%, transparent 0%), -webkit-radial-gradient(3.9% 29%, circle closest-corner, #CCCCCC, #CCCCCC 34.5%, transparent 0%);
	/* background-image: -moz-linear-gradient(top, transparent, transparent 19px, #E7EFF8 0px), -moz-radial-gradient(4% 50%, circle closest-corner, #f5f5f5, #f5f5f5 39%, transparent 0%), -moz-radial-gradient(3.9% 46%, circle closest-corner, #CCCCCC, #CCCCCC 43.5%, transparent 0%);
	background-image: -webkit-linear-gradient(top, transparent, transparent 19px, #E7EFF8 0), -webkit-radial-gradient(4% 50%, circle closest-corner, #f5f5f5, #f5f5f5 39%, transparent 0%), -webkit-radial-gradient(3.9% 46%, circle closest-corner, #CCCCCC, #CCCCCC 43.5%, transparent 0%); */
	-webkit-background-size: 100% 20px;
	background-size: 100% 20px;
}

.border-tbrl {
    border: 1.5px solid #000;
}

.border-t {
    border-top: 1.5px solid #000;
}
.border-b {
    border-bottom: 1.5px solid #000;
}
.border-l {
    border-left: 1.5px solid #000;
}
.border-r {
    border-right: 1.5px solid #000;
}

.answer-underlined {
    border-bottom: 2px solid black;
    margin-left: 1rem;
    width: 33%;
}

.button-save {
    margin-top: 1rem;
    margin-bottom: 1rem;
}