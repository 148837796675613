@import url(https://fonts.googleapis.com/css2?family=Caveat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-next-section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-top: 2rem;
}
h2 {
    text-transform: uppercase;
}

p {
    text-align: justify;
}

.image-100-width {
    width: 100%;
}
.heading {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    margin-bottom: 2rem;
}
.assignment {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.alert-fade-fix {
    opacity: 1!important;
}

.nalogaHeadingItem {
    margin-left: 1rem;
}

.uppercase {
    text-transform: uppercase;
}

.border-none {
    border: none!important;
}

.text-2-rem h2 {
    font-size: 3rem;
}

.center-center-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.flex-vertical-center {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.table-row {
    display: -webkit-flex;
    display: flex;
}

.flex {
    display: -webkit-flex;
    display: flex;
}

.flex-column {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.padding-1 {
    padding: 1rem;
}

.margin-top-1 {
    margin-top: 1rem;
}

.padding-05rem {
    padding: .5rem;
}

.square10 {
    width: 10%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square20 {
    width: 20%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square25 {
    width: 25%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square40 {
    width: 40%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square50 {
    width: 50%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square60 {
    width: 60%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square75 {
    width: 75%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square80 {
    width: 80%;
    border: 1.5px solid #000;
    padding: 10px;
}

.square90 {
    width: 90%;
    border: 1.5px solid #000;
    padding: 10px;
}

.flex-20 {
    width: 20%;
}

.flex-80 {
    width: 80%;
}

.flex-25 {
    width: 25%;
}

.flex-75 {
    width: 75%;
}

.flex-30 {
    width: 30%;
}

.flex-70 {
    width: 70%;
}

.width20 {
    width: 20%;
    padding: 10px;
}

.width80 {
    width: 80%;
    padding: 10px;
}

.width50 {
    width: 50%;
    padding: 10px;
}

.task-input {
    font-family: 'Caveat', cursive;
}

.task-input textarea {
    font-size: 1.5rem;
}

.task-input-size-normal textarea {
    font-size: 1rem;
}

.large-number-input {
    font-size: 2rem!important;
}

.clickable {
    cursor: pointer;
}

.x {
    font-family: 'Caveat', cursive;
    font-size: 2.5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.obkrozi-vrednote {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 2rem 0;
}

.obkrozi-vrednote div {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    width: 33%;
    padding-bottom: .2rem;
    margin-bottom: 1rem;
}

.circled {
    position: relative;
}
.circled:after {
    border: .25em solid #16264c;
    border-radius: 1.5em;
    bottom: -.3em;
    content: '';
    left: -1em;
    position: absolute;
    right: 10em;
    top: -.1em;
}

.circled-number {
    position: relative;
}

.circled-number:after {
    border: .15em solid #16264c;
    border-radius: 1.5em;
    bottom: -.3em;
    content: '';
    left: -.5em;
    position: absolute;
    right: -0.25em;
    top: -.1em;
}

.notebook-like {
	color: #000000;
	border: 1px solid #EEEEEE;
	box-shadow: 1px 1px 0 #DDDDDD;
	display: inline-block;
	vertical-align: top;
    font-family: 'Caveat', cursive;
	line-height: 20px;
	margin: 2% auto;
	padding: 22px 20px 3px 40px;
	min-height: 200px;
	width: 600px;
	background-color: #FFFFFF;
	background-image: -webkit-linear-gradient(top, transparent, transparent 19px, #E7EFF8 0), -webkit-radial-gradient(4% 27%, circle closest-corner, #f5f5f5, #f5f5f5 30%, transparent 0%), -webkit-radial-gradient(3.9% 29%, circle closest-corner, #CCCCCC, #CCCCCC 34.5%, transparent 0%);
	/* background-image: -moz-linear-gradient(top, transparent, transparent 19px, #E7EFF8 0px), -moz-radial-gradient(4% 50%, circle closest-corner, #f5f5f5, #f5f5f5 39%, transparent 0%), -moz-radial-gradient(3.9% 46%, circle closest-corner, #CCCCCC, #CCCCCC 43.5%, transparent 0%);
	background-image: -webkit-linear-gradient(top, transparent, transparent 19px, #E7EFF8 0), -webkit-radial-gradient(4% 50%, circle closest-corner, #f5f5f5, #f5f5f5 39%, transparent 0%), -webkit-radial-gradient(3.9% 46%, circle closest-corner, #CCCCCC, #CCCCCC 43.5%, transparent 0%); */
	background-size: 100% 20px;
}

.border-tbrl {
    border: 1.5px solid #000;
}

.border-t {
    border-top: 1.5px solid #000;
}
.border-b {
    border-bottom: 1.5px solid #000;
}
.border-l {
    border-left: 1.5px solid #000;
}
.border-r {
    border-right: 1.5px solid #000;
}

.answer-underlined {
    border-bottom: 2px solid black;
    margin-left: 1rem;
    width: 33%;
}

.button-save {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.wrapper {
    padding: 0 0.5ch;
  }
  
  .text {
    color: #4682b4;
    text-decoration: underline;
  }
.background {
  background-image: url(/static/media/trening-genialnosti.ec8b23e0.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

.main {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.footer {
  height: 5rem;
  background-color: #e6e6e6;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.footer-copy {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.navigation div a {
  text-decoration: none;
  color: #212529;
}

.navigation .list-group-item {
  padding: .1rem 1rem;
}

.title {
  text-transform: uppercase;
  text-align: center;
  font-size: 4rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  /* padding: 2rem 0; */
}

.bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20rem;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.bottom p {
  color: #fff;
  margin-top: 1rem;
}

.subtitle {
  color: #000;
  padding: .5rem 1rem;
}

.subtitleWrapper {
  background-color: #FFDE59;
  margin: 0 auto;
  width: 30rem;
  text-align: center;
  border-radius: 3px;
}

.content {
  background-color: #009599;
  height: 100vh;
  position: relative;
}

a.rs-btn.rs-btn-subtle.rs-dropdown-toggle {
  font-weight: 500;
}

.rs-btn-subtle {
  color: #3a3a3a;
  background-color: transparent;
}

.rs-nav-item > .rs-nav-item-content {
  color: #3a3a3a;
  font-weight: 500;
}

.marginTopBottom1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
