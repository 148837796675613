.background {
  background-image: url("./images/trening-genialnosti.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

.main {
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.footer {
  height: 5rem;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
}

.footer-copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation div a {
  text-decoration: none;
  color: #212529;
}

.navigation .list-group-item {
  padding: .1rem 1rem;
}

.title {
  text-transform: uppercase;
  text-align: center;
  font-size: 4rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  /* padding: 2rem 0; */
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20rem;
  flex-direction: column;
}

.bottom p {
  color: #fff;
  margin-top: 1rem;
}

.subtitle {
  color: #000;
  padding: .5rem 1rem;
}

.subtitleWrapper {
  background-color: #FFDE59;
  margin: 0 auto;
  width: 30rem;
  text-align: center;
  border-radius: 3px;
}

.content {
  background-color: #009599;
  height: 100vh;
  position: relative;
}

a.rs-btn.rs-btn-subtle.rs-dropdown-toggle {
  font-weight: 500;
}

.rs-btn-subtle {
  color: #3a3a3a;
  background-color: transparent;
}

.rs-nav-item > .rs-nav-item-content {
  color: #3a3a3a;
  font-weight: 500;
}

.marginTopBottom1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}